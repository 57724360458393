<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="550px"
    @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="标签名" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="颜色" prop="colour">
        <el-color-picker v-model="form.colour" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  export default {
    name: 'Edit',
    data() {
      return {
        form: {
          name: '',
          colour: '#409EFF',
        },
        rules: {
          name: [
            { required: true, message: '请填写标签名', trigger: 'blur' }
          ],
          colour: [
            { type: 'date', required: true, message: '请选择颜色', trigger: 'change' }
          ],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      showEdit(row) {
        if (!row) {
          this.title = '添加'
        } else {
          this.title = '修改'
          this.form = Object.assign({}, row)
        }
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { code, message } = await doEdit(this.form)
            if (code == '200') {
              this.$message.success(message)
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message.error(message)
              this.$emit('fetch-data')
              this.close()
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
