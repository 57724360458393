<template>
  <div class="merchant-container">
    <div style="float: left; margin: 5px 0 0 20px">
      <el-button type="success" size="small">导入实物类发货信息</el-button>
      <el-button type="primary" @click="exportInKindWinning" size="small">导出实物类中奖记录</el-button>
      <el-button type="danger" @click="exportAllWinningRecords" size="small">导出所有中奖记录</el-button>
    </div>
    <div style="float: right">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <el-form-item>
          <el-select
              v-model="queryForm.name"
              clearable
              placeholder="请选择活动"
              size="small"
          >
            <el-option
                v-for="item in apiName"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryForm.kind" placeholder="请选择奖品类型" size="small">
            <el-option label="实物类" value="0"></el-option>
            <el-option label="非实物类" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
        :data="list"
        size="small"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="user_id"
          label="用户名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="抽奖活动">
      </el-table-column>
      <el-table-column
          align="center"
          prop="reward"
          label="奖品">
      </el-table-column>
      <el-table-column
          align="center"
          prop="kind"
          label="奖品类型">
      </el-table-column>
      <el-table-column
          align="center"
          prop="contact_receiver"
          label="收货人">
      </el-table-column>
      <el-table-column
          align="center"
          prop="addr"
          label="收货地址">
      </el-table-column>
      <el-table-column
          align="center"
          prop="mobile"
          label="手机号码">
      </el-table-column>
      <el-table-column
          align="center"
          prop="logi_id"
          label="快递">
      </el-table-column>
      <el-table-column
          align="center"
          prop="shipping_no"
          label="快递单号">
      </el-table-column>
      <el-table-column align="center" label="中奖时间">
        <template slot-scope="scope">
          {{ scope.row.create_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)" v-if="row.kind == '0'">编辑物流信息</el-button>
          <el-button type="text" @click="handleEdit(row)" v-else disabled>编辑物流信息</el-button>
          <!--          <el-button type="text" @click="handleDelete(row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        style="text-align: center;"
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
    <!--  </el-card>-->
  </div>
</template>
<script>
import Edit from './components/labelEdit'
import {formatDate} from "@/utils/theTimeStamp";
import {baseURL} from "@/config";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      picApi: baseURL + '/base/upload/upload-file',
      activeName: '1',
      apiName: [],
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        name: '',
        start_date: '',
        end_date: '',
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
            {name: '下午'},
          ],
          merchant_id: '1',
          is_check: '0',
          color: '#00afff',
          kind: '1',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
          ],
          merchant_id: '1',
          is_check: '1',
          color: '#000000',
          kind: '0',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
          ],
          merchant_id: '1',
          is_check: '2',
          color: '#ffff00',
          kind: '1',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
    this.requestName()
  },
  mounted() {
  },
  methods: {
    // 获取活动名称
    async requestName() {
      const {data} = await matchBranchList()
      this.apiName = data
    },

    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      const {list} = data
      for (let i = 0; i < list.length; i++) {
        list[i]['state'] = list[i]['state'].toString()
      }
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },

    // 导出实物类中奖记录
    async exportInKindWinning() {
      const {code, data} = await inter()
      if (code == 200) {
        console.log(data)
        window.location.href = data[0]
      }
    },

    // 导出所有中奖记录
    async exportAllWinningRecords() {
      const {code, data} = await inter()
      if (code == 200) {
        console.log(data)
        window.location.href = data[0]
      }
    },

    // 导入
    handleExceed(files, filePic) {
      this.$message.warning(
          `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
              files.length + filePic.length
          } 个文件`
      )
    },
    onUploadSucc() {
      this.$message.success('导入成功')
      this.reload()
    },
  }
}

</script>

